import { enerbitColorsV2 } from "@enerbit/base";
import { Avatar, Box, Typography } from "@mui/material";
import moment from "moment";
import Config from "../../../config/config";
import { useHelpDeskStore } from "../../../store/pqrs.store";
import { getURLUserImage } from "../../../utils";
import {
    BoxBorder,
    BoxInfo,
    CommentsSection,
    ServiceInfoLabel,
    SkeletonCenterContent,
} from "./components";
export const CenterContent = () => {
    const { pqrSelected, isLoadingPqrs, isEmptyPqrs } = useHelpDeskStore();

    if (!pqrSelected && isLoadingPqrs) {
        return <SkeletonCenterContent />;
    }
    if (isEmptyPqrs()) {
        return (
            <Box>
                <Typography variant="h5">
                    No hay PQRs para el cliente
                </Typography>
            </Box>
        );
    }
    if (!pqrSelected) {
        return (
            <Box>
                <Typography variant="h5">No hay PQR seleccionada</Typography>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            borderRight={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
            borderLeft={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
        >
            <Box
                display="flex"
                padding="24px"
                borderBottom={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
                justifyContent="space-between"
            >
                <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="h5" fontWeight="bold">
                        {pqrSelected.name}
                    </Typography>
                    <Box display="flex" gap="8px">
                        <BoxBorder>
                            <Typography
                                variant="overline"
                                color="secondary"
                                fontWeight="bold"
                            >
                                TICKET NO.{" "}
                                <Typography variant="overline" fontWeight={400}>
                                    {pqrSelected.id}
                                </Typography>
                            </Typography>
                        </BoxBorder>
                        <BoxBorder>
                            <Typography
                                variant="overline"
                                color={enerbitColorsV2.primary.gray[600]}
                                fontWeight="bold"
                            >
                                FECHA DE CREACIÓN:{" "}
                                <Typography variant="overline" fontWeight={400}>
                                    {moment(
                                        pqrSelected.pqr_creation_date,
                                    ).format("DD/MM/YYYY")}
                                </Typography>
                            </Typography>
                        </BoxBorder>
                        <Box>
                            <Typography
                                variant="overline"
                                color={enerbitColorsV2.primary.gray[600]}
                                fontWeight="bold"
                            >
                                HORA:{" "}
                                <Typography variant="overline" fontWeight={400}>
                                    {moment(
                                        pqrSelected.pqr_creation_date,
                                    ).format("HH:mm")}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Avatar src={getURLUserImage(pqrSelected.assigned_to_id)} />
            </Box>
            <BoxInfo>
                <ServiceInfoLabel
                    title="ID del servicio asociado: "
                    value={pqrSelected.service_id}
                    icon="bolt"
                    onClick={() => {
                        window.open(
                            `${Config.getInstance().estadiumURL}/#/electricity-supply-service/attention/${pqrSelected.service_id}`,
                            "_blank",
                        );
                    }}
                />
            </BoxInfo>
            <BoxInfo>
                <>
                    <ServiceInfoLabel
                        width="40%"
                        title="Propietario del servicio: "
                        value={pqrSelected.owner_name}
                    />
                    <ServiceInfoLabel
                        width="40%"
                        title="Nombre solicitante: "
                        value={pqrSelected.customer_name}
                        onClick={() => {}}
                    />
                    <ServiceInfoLabel
                        width="20%"
                        title="Canal de contacto: "
                        value={pqrSelected.application_method}
                    />
                </>
            </BoxInfo>
            <CommentsSection />
        </Box>
    );
};
