import { Box } from "@mui/material";
import { useEffect } from "react";
import { PqrMicroFrontendProps } from "../root.component";
import { useHelpDeskStore } from "../store/store";
import { CenterContent, LeftSideContent, RightContent } from "./components";

export const PqrPage = ({ email }: PqrMicroFrontendProps) => {
    const { onGetPqrs: getPqrs } = useHelpDeskStore();

    useEffect(() => {
        getPqrs({
            partner_email: email,
        });
    }, []);

    return (
        <Box width="100%" display="flex">
            <Box width="22%">
                <LeftSideContent email={email} />
            </Box>
            <Box width="56%">
                <CenterContent />
            </Box>
            <Box width="22%">
                <RightContent />
            </Box>
        </Box>
    );
};
