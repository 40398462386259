import { enerbitColorsV2 } from "@enerbit/base";
import { Avatar, Box, Button, InputAdornment, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Config from "../../../config/config";
import { useHelpDeskStore } from "../../../store/store";
import {
    calculateEndDate,
    getLabelRemainingDays,
    getURLUserImage,
} from "../../../utils";
import { CustomIcon } from "../Common";
import { CustomFormGroupDisabled } from "./components";
interface KanbaState {
    color: string;
    themeColor: "success" | "error" | "warning" | "info";
    message: string;
}
export const RightContent = () => {
    const { pqrSelected } = useHelpDeskStore();
    const [remainingDaysLabel, setRemainingDaysLabel] = useState<string>("");
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [kanbaState, setKanbaState] = useState<KanbaState | null>(null);
    useEffect(() => {
        if (pqrSelected) {
            const { label } = getLabelRemainingDays(
                moment(pqrSelected.pqr_creation_date),
                15,
            );
            setRemainingDaysLabel(label);
        }
    }, [pqrSelected]);

    useEffect(() => {
        if (pqrSelected) {
            setEndDate(
                calculateEndDate(moment(pqrSelected.pqr_creation_date), 15),
            );
        }
    }, [pqrSelected]);

    useEffect(() => {
        if (pqrSelected) {
            setKanbaState(_kanbaStateToMessage());
        }
    }, [pqrSelected]);

    const _kanbaStateToMessage = (): KanbaState => {
        switch (pqrSelected?.kanban_state) {
            case "normal":
                return {
                    color: enerbitColorsV2.primary.gray[600],
                    themeColor: "info",
                    message: "En proceso",
                };
            case "done":
                return {
                    color: enerbitColorsV2.primary.success[600],
                    themeColor: "success",
                    message: "Listo",
                };
            case "blocked":
                return {
                    color: enerbitColorsV2.primary.error[600],
                    themeColor: "error",
                    message: "Bloqueado",
                };
            default:
                return {
                    color: enerbitColorsV2.primary.gray[600],
                    themeColor: "info",
                    message: pqrSelected?.kanban_state ?? "",
                };
        }
    };

    if (!pqrSelected || !endDate || !kanbaState) {
        return <></>;
    }

    const _handleOnClickSeeOdoo = (): void => {
        window.open(
            `${Config.getInstance().odooURL}/web#id=${pqrSelected.id}&model=helpdesk.ticket&view_type=form`,
            "_blank",
        );
    };

    return (
        <Box display="flex" flexDirection="column" className="side-content">
            <Box
                display="flex"
                padding="16px"
                flexDirection="column"
                gap="16px"
                borderBottom={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
            >
                <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={_handleOnClickSeeOdoo}
                    sx={{
                        justifyContent: "space-between",
                        textTransform: "none",
                    }}
                    endIcon={
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: "14px",
                                color: "white",
                            }}
                        >
                            arrow_outward
                        </span>
                    }
                >
                    Ver en odoo
                </Button>
                <Box display="flex" gap="8px">
                    <Typography
                        fontWeight="bold"
                        color={enerbitColorsV2.primary.gray[600]}
                        variant="body2"
                        fontSize="14px"
                        whiteSpace="nowrap"
                    >
                        Respuesta oportuna:
                    </Typography>
                    <Typography
                        color={enerbitColorsV2.primary.gray[600]}
                        variant="body2"
                        fontSize="14px"
                    >
                        {endDate?.format("DD/MM/YYYY")}
                    </Typography>
                    <Typography
                        color={enerbitColorsV2.primary.error[500]}
                        variant="caption"
                    >
                        {remainingDaysLabel}
                    </Typography>
                </Box>
            </Box>
            <Box
                display="flex"
                padding="16px"
                flexDirection="column"
                gap="20px"
                borderBottom={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
            >
                <CustomFormGroupDisabled
                    title="Etapa"
                    description={pqrSelected.stage_name}
                />
                <CustomFormGroupDisabled
                    title="Estado"
                    description={kanbaState.message}
                    textFieldColor={kanbaState.themeColor}
                    startAdornment={
                        <InputAdornment position="start">
                            <CustomIcon
                                iconName="check_circle"
                                fontSize="16px"
                                color={kanbaState.color}
                            />
                        </InputAdornment>
                    }
                />
                <CustomFormGroupDisabled
                    title="Asignado a"
                    description={pqrSelected.assigned_to_name}
                    startAdornment={
                        <InputAdornment position="start">
                            <Avatar
                                src={getURLUserImage(
                                    pqrSelected.assigned_to_id,
                                )}
                                sx={{
                                    width: "24px",
                                    height: "24px",
                                }}
                            />
                        </InputAdornment>
                    }
                />
                <CustomFormGroupDisabled
                    title="Tipo"
                    description={pqrSelected.type}
                />
                <CustomFormGroupDisabled
                    title="Causal"
                    description={pqrSelected.types_and_causes_name}
                />
            </Box>
            <Box
                display="flex"
                padding="16px"
                flexDirection="column"
                gap="20px"
            >
                <CustomFormGroupDisabled
                    title="Tipo de respuesta"
                    description={pqrSelected.response_type}
                />
                <CustomFormGroupDisabled
                    title="Tipo de notificación"
                    description={pqrSelected.notification_type}
                />
            </Box>
        </Box>
    );
};
