import { enerbitColorsV2 } from "@enerbit/base";
import { Avatar, Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import Config from "../../../../config/config";
import { getImageType } from "../../../../utils";
import "./styles/card-comment.scss";

export type CardCommentProps = {
    title: string;
    body: string;
    helpText?: string;
    imageUrl?: string;
};
export const CardComment = ({
    body,
    helpText,
    imageUrl,
    title,
}: CardCommentProps) => {
    const [descriptionHTML, setDescriptionHTML] = useState<string>(body);

    useEffect(() => {
        const sanitizedDescription = DOMPurify.sanitize(body);
        setDescriptionHTML(sanitizedDescription);
    }, [body]);

    const imageType = imageUrl ? getImageType(imageUrl) : "";

    return (
        <Box className="card-comment-container">
            <Box display="flex" gap="14px" alignItems="center">
                {imageUrl && (
                    <Avatar
                        sx={{
                            height: "34px",
                            width: "34px",
                        }}
                        src={`data:${imageType};base64,${imageUrl}`}
                    />
                )}
                <Box>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={enerbitColorsV2.primary.gray[800]}
                    >
                        {title}
                    </Typography>

                    {helpText && (
                        <Typography
                            variant="caption"
                            color={enerbitColorsV2.primary.gray[500]}
                        >
                            {helpText}
                        </Typography>
                    )}
                </Box>
            </Box>
            <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
        </Box>
    );
};

export type CardDescriptionProps = {
    description: string;
};
export const CardDescription = ({ description }: CardDescriptionProps) => {
    const [descriptionHTML, setDescriptionHTML] = useState<string>(description);

    useEffect(() => {
        const sanitizedDescription = DOMPurify.sanitize(description);
        const modifiedDescription = modifyImageSrc(sanitizedDescription);
        setDescriptionHTML(modifiedDescription);
    }, [description]);

    const modifyImageSrc = (html: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const images = doc.querySelectorAll("img");

        images.forEach((img) => {
            const originalSrc = img.getAttribute("src");
            if (originalSrc) {
                const odooURL = Config.getInstance().odooURL;
                if (odooURL) {
                    const modifiedSrc = `${odooURL}${originalSrc}`;
                    img.setAttribute("src", modifiedSrc);
                }
            }
        });

        return doc.body.innerHTML;
    };

    return (
        <Box className="card-comment-container" marginBottom="12px">
            <Typography
                variant="body2"
                fontWeight="bold"
                color={enerbitColorsV2.primary.gray[800]}
            >
                Descripción
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
        </Box>
    );
};
