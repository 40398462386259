import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/serviceAccount.scss";

import {
	ArrowCircleLeftOutlinedIcon,
	Box,
	Button,
	CircularProgress,
	IconButton,
} from "@enerbit/base";

import { useSearchParams } from "react-router-dom";
import type { StateStorage } from "src/models/stateStorage";
import logo from "../../assets/img/nodata.png";
import {
	getServiceAccountsRelationships,
	getServiceAccountsRelationshipsByServiceAccount,
	isLoading,
	servicesAccountData,
	setDefaultServiceAccount,
	setDefaultServicesAccountData,
	setServicesAccountSelect,
	setTabSelectedAccessManagement,
} from "../../features/serviceAccount/serviceAccountSlice";
import {
	getSearchUser,
	searchUser,
} from "../../features/serviceUser/serviceUserSlice";
import {
	mountedDisassembleAppNotifications,
	mountedDisassembleAppPayments,
	mountedDisassembleInvoices,
	mountedDisassemblePqrs,
} from "../../helpers/helperSingleSpa";
import type { AppDispatch } from "../../store/store";
import TabsComponent from "./components/TabsComponent";
import ServiceAccount from "./components/reusable/ServiceAccount";
import UserMainInformation from "./components/reusable/UserMainInformation";

const ServiceAccountMain = () => {
	// const PqrsComponent = lazy(() => import("pqrs/PqrsRoot"));
	const dispatch = useDispatch<AppDispatch>();
	const servicesAccount = useSelector(servicesAccountData);
	const [params] = useSearchParams();

	const { isLoadingSingleServiceAccount } = useSelector(
		(state: StateStorage) => state.serviceAccount,
	);
	const isLoadingRelationships = useSelector(isLoading);
	const dataUser = useSelector(searchUser);

	const loadInitialValues = async () => {
		dispatch(setServicesAccountSelect(""));
		dispatch(setTabSelectedAccessManagement(0));
		dispatch(setDefaultServicesAccountData());
		dispatch(setDefaultServiceAccount());
	};

	useEffect(() => {
		const serviceAccountId = params.get("serviceGroup");
		if (serviceAccountId) {
			dispatch(
				getServiceAccountsRelationshipsByServiceAccount({
					page: 0,
					service_account_id: serviceAccountId,
					size: 8,
				}),
			);
		}
	}, []);

	useEffect(() => {
		dispatch(
			getServiceAccountsRelationships({
				page: servicesAccount.next_page ?? 0,
				size: 50,
			}),
		);
		dispatch(getSearchUser());
	}, []);

	useEffect(() => {
		loadInitialValues();
	}, []);

	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		if (dataUser) {
			if (value === 1) {
				mountedDisassembleAppPayments(dataUser?.id);
			} else if (value === 2) {
				mountedDisassembleInvoices({
					user_id: dataUser.id,
				});
			} else if (value === 3) {
				mountedDisassembleAppNotifications(dataUser?.id);
			} else if (value === 4) {
				mountedDisassemblePqrs(dataUser?.username);
			}
		}
	}, [value]);

	const tabs = [
		{
			label: "Gestión de servicios",
			component: <ServiceAccount user_id={dataUser?.id ?? ""} />,
		},
		{
			label: "Pagos",
			component: <div id="single-spa-application:app-payments" />,
		},
		{
			label: "Facturas",
			component: <div id="single-spa-application:app-invoices" />,
		},
		{
			label: "Notificaciones",
			component: <div id="single-spa-application:app-notifications" />,
		},
		{
			label: "PQRs",
			component: <div id="single-spa-application:app-pqrs" />,
		},
		{
			label: "Documentación",
			component: <div id="single-spa-application:app-invoices" />,
		},
	];

	if (
		(isLoadingRelationships || isLoadingSingleServiceAccount) &&
		servicesAccount.items.length == 0
	) {
		return (
			<Box className="Loading" sx={{ alignItems: "center", height: "50vh" }}>
				<CircularProgress />
			</Box>
		);
	}
	return (
		<Box className="Component-root">
			{!isLoading &&
			(servicesAccount.items.length == 0 || dataUser?.pii == null) ? (
				<Box className="Container-users-data">
					<Box sx={{ textAlign: "center" }}>
						<img src={logo} alt="Logo" />
						<Box className="Header-title-nodata">¡Usuario sin datos!</Box>
						<Box sx={{ color: "var(--color-neutral500)" }}>
							El usuario{" "}
							{dataUser?.pii != null
								? `${dataUser?.pii.names} ${dataUser?.pii.last_names}`
								: ""}{" "}
							no dispone hasta el momento
						</Box>
						<Box sx={{ color: "var(--color-neutral500)" }}>
							con datos registrados
							{dataUser?.pii == null ? "." : " como grupo de facturas o pagos."}
						</Box>
						<Button
							color="secondary"
							size="small"
							variant="contained"
							sx={{ mt: "20px" }}
							onClick={() => window.history.back()}
						>
							<Box style={{ fontWeight: "bold", fontSize: "16px" }}>Volver</Box>
						</Button>
					</Box>
				</Box>
			) : (
				<>
					<Box>
						<IconButton
							sx={{ padding: "1px !important" }}
							onClick={() => {
								dispatch(setServicesAccountSelect(""));
								window.history.back();
							}}
						>
							<ArrowCircleLeftOutlinedIcon className="Icon-back" />
						</IconButton>
					</Box>
					<UserMainInformation />
					<Box sx={{ width: "100%", mt: "30px" }}>
						<TabsComponent
							tabs={tabs}
							value={value}
							handleChange={handleChange}
						/>
					</Box>
				</>
			)}
		</Box>
	);
};

export default ServiceAccountMain;
