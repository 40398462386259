import { enerbitColorsV2 } from "@enerbit/base";
import { Box, Skeleton } from "@mui/material";
import { BoxInfo } from "./BoxInfo";
import { SkeletonComments } from "./SkeletonComments";

export const SkeletonCenterContent = () => {
    return (
        <Box width="100%">
            <Box
                display="flex"
                flexDirection="column"
                borderRight={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
                borderLeft={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
            >
                <Box
                    display="flex"
                    padding="24px"
                    borderBottom={`1px solid ${enerbitColorsV2.primary.gray[200]}`}
                    justifyContent="space-between"
                >
                    <Box display="flex" flexDirection="column" gap="8px">
                        <Skeleton variant="text" width="60%" />
                        <Box display="flex" gap="8px">
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={20}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={20}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={20}
                            />
                        </Box>
                    </Box>
                    <Skeleton variant="circular" width={40} height={40} />
                </Box>
                <BoxInfo>
                    <>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                    </>
                </BoxInfo>
                <BoxInfo>
                    <>
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="20%" />
                    </>
                </BoxInfo>
                <SkeletonComments />
            </Box>
        </Box>
    );
};
