import { EnerbitPagination } from "@enerbit/base";
import { create } from "zustand";
import { Pqr } from "../models";
import { getComments, getPqrs } from "../service";

type OnGetComments = {
    pqr_id: number;
    size?: number;
    page?: number;
};

type OnGetPqrsProps = {
    partner_email: string;
    page?: number;
    size?: number;
    name?: string;
    reset?: boolean;
};
type HelpDeskStore = {
    // State values
    paginationPqrs: EnerbitPagination<Pqr> | null;
    isLoadingPqrs: boolean;
    pqrSelected: Pqr | null;
    // Callbacks
    onGetPqrs: (params: OnGetPqrsProps) => void;
    onSetPqrSelected: (pqr: Pqr) => void;
    onCleanState: () => void;
    isEmptyPqrs: () => boolean;
    onGetComments: (params: OnGetComments) => void;
    isLoadingComments: boolean;
};

export const useHelpDeskStore = create<HelpDeskStore>()((set, get) => ({
    // State values
    paginationPqrs: null,
    isLoadingPqrs: false,
    pqrSelected: null,
    isLoadingComments: false,
    // Callbacks
    onSetPqrSelected: (pqr: Pqr) => {
        set({ pqrSelected: pqr });
    },
    onGetComments: async ({ pqr_id, page, size }) => {
        try {
            set({ isLoadingComments: true });
            const response = await getComments({
                res_id: pqr_id,
                page,
                size,
            });
            set({
                pqrSelected: {
                    ...get().pqrSelected,
                    messages: {
                        ...response,
                        items: [
                            ...(get().pqrSelected?.messages?.items ?? []),
                            ...response.items,
                        ],
                    },
                },
                isLoadingComments: false,
            });
        } catch (error) {
            console.error(error);
        }
        set({ isLoadingComments: false });
    },
    onGetPqrs: async ({
        partner_email,
        name,
        page,
        size = 10,
        reset = true,
    }: OnGetPqrsProps) => {
        try {
            set({ isLoadingPqrs: true });
            if (reset) {
                set({ paginationPqrs: null, pqrSelected: null });
            }
            const response = await getPqrs({
                partner_email,
                name,
                page,
                size,
            });
            const currentState = get();
            if (!currentState.pqrSelected && response.items.length > 0) {
                set({ pqrSelected: response.items[0] });
            }
            set({
                paginationPqrs: {
                    ...response,
                    items: [
                        ...(get().paginationPqrs?.items ?? []),
                        ...response.items,
                    ],
                },
            });
        } catch (error) {
            console.error(error);
        }
        set({ isLoadingPqrs: false });
    },
    onCleanState: () => {
        set({ paginationPqrs: null, pqrSelected: null });
    },
    isEmptyPqrs: () => {
        const currentState = get();
        return (
            (!currentState.paginationPqrs ||
                currentState.paginationPqrs.items.length === 0) &&
            !currentState.isLoadingPqrs
        );
    },
}));
