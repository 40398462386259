import { enerbitColorsV2 } from "@enerbit/base";

export const getColorChipRemainingDays = (remainingDays: number): string => {
    if (remainingDays <= 5) {
        return enerbitColorsV2.primary.error[600];
    }
    if (remainingDays <= 10) {
        return enerbitColorsV2.primary.gray[600];
    }
    return enerbitColorsV2.primary.success[600];
};
