import { enerbitColorsV2 } from "@enerbit/base";

export type CustomIconProps = {
    iconName: string;
    fontSize?: string;
    color?: string;
};
export const CustomIcon = ({
    iconName,
    color = enerbitColorsV2.primary.gray[500],
    fontSize = "14px",
}: CustomIconProps) => {
    return (
        <span
            className="material-symbols-outlined"
            style={{
                fontSize,
                color,
            }}
        >
            {iconName}
        </span>
    );
};
