import { initEnviroment, themev2 } from "@enerbit/base";
import { ThemeProvider } from "@mui/material";

import moment from "moment";
import "moment/locale/es";
import { PqrPage } from "./pages/PqrPage";

moment.locale("es");
initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

export type PqrMicroFrontendProps = {
    email: string;
};

export default function Root({ email }: PqrMicroFrontendProps) {
    return (
        <ThemeProvider theme={themev2}>
            <PqrPage email={email} />
        </ThemeProvider>
    );
}
