import { Box, Skeleton } from "@mui/material";

export const LoadingCardPqr = () => {
    return (
        <Box className="card-pqr">
            <Box display="flex" justifyContent="space-between">
                <Box width="80%">
                    <Skeleton width="80%" />
                </Box>
                <Box width="20%">
                    <Skeleton width="100%" />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box width="30%">
                    <Skeleton width="100%" />
                </Box>
                <Box width="30%">
                    <Skeleton width="100%" />
                </Box>
            </Box>
        </Box>
    );
};
