export const getImageType = (base64: string): string => {
    if (base64.startsWith("/9j/")) {
        return "image/jpeg";
    } else if (base64.startsWith("iVBORw0KGgo")) {
        return "image/png";
    } else if (base64.startsWith("R0lGODdh") || base64.startsWith("R0lGODlh")) {
        return "image/gif";
    } else if (
        base64.startsWith(
            "PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0nVVRGLTgnID8+PHN2Zy",
        )
    ) {
        return "image/svg+xml";
    }
    return "image/jpeg";
};
