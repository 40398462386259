import { Box, Typography, enerbitColorsV2 } from "@enerbit/base";

export type ServiceInfoLabelProps = {
    title: string;
    value: string;
    icon?: string;
    onClick?: () => void;
    width?: string;
};
export const ServiceInfoLabel = ({
    title,
    value,
    icon,
    onClick,
    width,
}: ServiceInfoLabelProps) => {
    return (
        <Box display="flex" gap="4px" alignItems="center" width={width}>
            {icon && (
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "14px",
                        color: enerbitColorsV2.primary.gray[600],
                    }}
                >
                    {icon}
                </span>
            )}
            <Typography
                variant="body2"
                fontSize="14px"
                fontWeight="bold"
                color={enerbitColorsV2.primary.gray[600]}
                whiteSpace="nowrap"
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
                fontSize="14px"
                color={enerbitColorsV2.primary.gray[600]}
                sx={{
                    textDecorationLine: onClick && "underline",
                    cursor: onClick && "pointer",
                }}
                onClick={onClick}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
            >
                {value}
            </Typography>
        </Box>
    );
};
