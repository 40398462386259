import { enerbitColorsV2 } from "@enerbit/base";
import { Box, TextField, Typography } from "@mui/material";

export type CustomFormGroupProps = {
    textFieldColor?:
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning";
    startAdornment?: React.ReactNode;
    title: string;
    description: string;
};

export const CustomFormGroupDisabled = ({
    textFieldColor,
    startAdornment,
    title,
    description,
}: CustomFormGroupProps) => {
    return (
        <Box display="flex" flexDirection="column" gap="8px">
            <Typography
                variant="body2"
                fontWeight="bold"
                color={enerbitColorsV2.primary.gray[700]}
            >
                {title}
            </Typography>
            <TextField
                variant="outlined"
                disabled
                value={description}
                color={textFieldColor}
                slotProps={{
                    input: {
                        startAdornment,
                    },
                }}
            />
        </Box>
    );
};
