import { enerbitColorsV2 } from "@enerbit/base";
import { Box } from "@mui/material";

export type BoxInfoProps = {
    children: React.ReactNode;
};
export const BoxInfo = ({ children }: BoxInfoProps) => {
    return (
        <Box
            display="flex"
            padding="12px 24px"
            gap="16px"
            alignItems="center"
            borderBottom={`1px solid ${enerbitColorsV2.primary.gray[100]}`}
            width="100%"
        >
            {children}
        </Box>
    );
};
