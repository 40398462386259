import { Box, Skeleton } from "@mui/material";
import { VerticalLine } from "./VerticalLine";

export const SkeletonComments = () => {
    return (
        <Box className="comments-section-container">
            {[...Array(2)].map((_, index) => (
                <SkeletonItemComment
                    index={index}
                    key={`skeleton-item-${index}`}
                />
            ))}
        </Box>
    );
};

type SkeletonItemCommentProps = {
    index: number;
};

export const SkeletonItemComment = ({ index }: SkeletonItemCommentProps) => {
    return (
        <Box key={index} display="flex" flexDirection="column">
            <Skeleton variant="text" width="60%" />
            <VerticalLine />
            <Skeleton variant="rectangular" width="100%" height={50} />
            {index < 2 && (
                <VerticalLine key={`vertical-line-${index}`} height={12} />
            )}
        </Box>
    );
};
