import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import "./styles/common.scss";

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(_err, _info, _props) {
        return null;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
