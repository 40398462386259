import { EnerbitPagination, api } from "@enerbit/base";
import { Pqr, PqrMessage, StudioMediumRequest } from "../models";

export type GetPqrsProps = {
    page?: number;
    size?: number;
    user_id?: number;
    partner_email?: string;
    x_studio_medium_request?: StudioMediumRequest;
    x_causes?: number;
    name?: string;
};
export const getPqrs = async ({
    user_id,
    page,
    partner_email,
    size,
    x_causes,
    x_studio_medium_request,
    name,
}: GetPqrsProps) => {
    const res = await api.get<EnerbitPagination<Pqr>>(`/helpdesk/v1/pqrs`, {
        params: {
            page,
            size,
            user_id,
            partner_email,
            x_studio_medium_request,
            x_causes,
            name,
        },
    });
    return res.data;
};

export type GetCommentsProps = {
    res_id: number;
    page?: number;
    size?: number;
};
export const getComments = async ({
    res_id,
    page = 0,
    size = 10,
}: GetCommentsProps) => {
    const res = await api.get<EnerbitPagination<PqrMessage>>(
        `/helpdesk/v1/comments`,
        {
            params: {
                page,
                size,
                res_id,
            },
        },
    );
    return res.data;
};
