import moment from "moment";

// Lista de días festivos en Colombia (puedes agregar más días festivos según sea necesario)
const holidays = [
    "2023-01-01", // Año Nuevo
    "2023-01-09", // Día de los Reyes Magos
    "2023-03-20", // Día de San José
    "2023-04-06", // Jueves Santo
    "2023-04-07", // Viernes Santo
    "2023-05-01", // Día del Trabajo
    "2023-05-22", // Día de la Ascensión
    "2023-06-12", // Corpus Christi
    "2023-06-19", // Sagrado Corazón
    "2023-07-03", // San Pedro y San Pablo
    "2023-07-20", // Día de la Independencia
    "2023-08-07", // Batalla de Boyacá
    "2023-08-21", // La Asunción de la Virgen
    "2023-10-16", // Día de la Raza
    "2023-11-06", // Todos los Santos
    "2023-11-13", // Independencia de Cartagena
    "2023-12-08", // Inmaculada Concepción
    "2023-12-25", // Navidad
];

const isHoliday = (date: moment.Moment): boolean => {
    return holidays.includes(date.format("YYYY-MM-DD"));
};

const isBusinessDay = (date: moment.Moment): boolean => {
    const day = date.day();
    // Los días hábiles en Colombia son de lunes (1) a viernes (5) y no son festivos
    return day >= 1 && day <= 5 && !isHoliday(date);
};

export const calculateEndDate = (
    startDate: moment.Moment,
    businessDays: number,
): moment.Moment => {
    let count = 0;
    let currentDate = startDate.clone();

    while (count < businessDays) {
        currentDate.add(1, "days");
        if (isBusinessDay(currentDate)) {
            count++;
        }
    }
    return currentDate;
};

export const calculateBusinessDays = (
    startDate: moment.Moment,
    endDate: moment.Moment,
): number => {
    let businessDays = 0;
    const today = moment().startOf("day");

    if (endDate.isSame(today, "day")) {
        return 0;
    }

    if (endDate.isBefore(today, "day")) {
        for (
            let date = endDate.clone();
            date.isBefore(today);
            date.add(1, "days")
        ) {
            if (isBusinessDay(date)) {
                businessDays--;
            }
        }
        return businessDays;
    }

    for (
        let date = startDate.clone();
        date.isBefore(endDate);
        date.add(1, "days")
    ) {
        if (isBusinessDay(date)) {
            businessDays++;
        }
    }

    return businessDays;
};

export const getLabelRemainingDays = (
    startDate: moment.Moment,
    businessDays: number,
): { remainingDays: number; label: string } => {
    const start = startDate;
    const endDate = calculateEndDate(start, businessDays);
    const remainingDays = calculateBusinessDays(moment(), endDate);

    let label = "";
    if (remainingDays < 0) {
        label = "Vencido hace " + Math.abs(remainingDays) + " días";
    } else if (remainingDays === 0) {
        label = "Último día";
    } else if (remainingDays === 1) {
        label = "Queda 1 día";
    } else {
        label = `Quedan ${remainingDays} días`;
    }

    return { remainingDays, label };
};
