import { enerbitColorsV2 } from "@enerbit/base";
import { Box } from "@mui/material";

export type VerticalLineProps = {
    height?: number;
};
export const VerticalLine = ({ height = 14 }: VerticalLineProps) => {
    return (
        <Box
            component="svg"
            sx={{
                width: "1px",
                height: `${height}px`,
                margin: "0 16px",
            }}
        >
            <line
                x1="0"
                y1="0"
                x2="0"
                y2={height}
                stroke={enerbitColorsV2.primary.gray[200]}
                strokeWidth="1"
            />
        </Box>
    );
};
