import { enerbitColorsV2 } from "@enerbit/base";
import { Box } from "@mui/material";

export type BoxBorderProps = {
    children: React.ReactNode;
};

export const BoxBorder: React.FC<BoxBorderProps> = ({ children }) => {
    return (
        <Box
            borderRight={`1px solid ${enerbitColorsV2.primary.gray[300]}`}
            paddingRight="8px"
        >
            {children}
        </Box>
    );
};
