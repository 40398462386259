import { enerbitColorsV2 } from "@enerbit/base";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useHelpDeskStore } from "../../../../store/pqrs.store";
import { CardComment, CardCommentProps, CardDescription } from "./CardComment";
import { SkeletonComments, SkeletonItemComment } from "./SkeletonComments";
import "./styles/comments-section.scss";
import { VerticalLine } from "./VerticalLine";
export const CommentsSection = () => {
    const { pqrSelected, onGetComments, isLoadingComments } =
        useHelpDeskStore();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pqrSelected?.id) {
            onGetComments({
                pqr_id: pqrSelected.id,
            });
        }
    }, [pqrSelected?.id]);

    if (!pqrSelected) {
        return <></>;
    }

    const loadComments = async () => {
        if (pqrSelected?.messages?.next_page) {
            onGetComments({
                pqr_id: pqrSelected.id,
                page: pqrSelected.messages.next_page,
            });
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [containerRef.current, isLoadingComments]);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                containerRef.current;

            if (
                scrollTop + clientHeight >= scrollHeight - 200 &&
                !isLoadingComments
            ) {
                loadComments();
            }
        }
    };

    const _extractName = (emailFrom: string): string => {
        const match = emailFrom.match(/"([^"]+)"/);
        return match ? match[1] : emailFrom;
    };

    const _getComments = () => {
        if (
            isLoadingComments &&
            (pqrSelected?.messages?.items.length ?? 0) === 0
        ) {
            return <SkeletonComments />;
        }
        return pqrSelected?.messages?.items.map((comment, index) => (
            <Box key={`comment-container-${comment.id}`}>
                <CommentsContainer
                    key={`comment-${comment.id}`}
                    title={_extractName(comment.email_from)}
                    body={comment.body}
                    titleCard={moment(comment.date).format(
                        "DD [de] MMMM [del] YYYY",
                    )}
                    imageUrl={comment.author_avatar}
                />
                {index < pqrSelected?.messages?.items.length - 1 && (
                    <VerticalLine
                        key={`vertical-line-${comment.id}`}
                        height={12}
                    />
                )}
            </Box>
        ));
    };

    return (
        <Box ref={containerRef} className="comments-section-container">
            <CardDescription
                key="pqr-description"
                description={pqrSelected.description}
            />
            {_getComments()}
            {(pqrSelected?.messages?.items?.length ?? 0) > 0 &&
                isLoadingComments && (
                    <Box marginTop="12px">
                        <VerticalLine
                            key={`vertical-line-infinite-scroll`}
                            height={12}
                        />
                        <SkeletonItemComment index={21} />
                    </Box>
                )}
        </Box>
    );
};

const CommentsContainer = ({
    title,
    body,
    helpText,
    imageUrl,
    titleCard,
}: CardCommentProps & { titleCard: string }) => {
    return (
        <Box display="flex" flexDirection="column">
            <Typography
                variant="caption"
                color={enerbitColorsV2.primary.gray[400]}
            >
                {titleCard}
            </Typography>
            <VerticalLine />
            <CardComment
                body={body}
                title={title}
                helpText={helpText}
                imageUrl={imageUrl}
            />
        </Box>
    );
};
