import { Box, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHelpDeskStore } from "../../../store/pqrs.store";
import { CardPqr } from "../CardPqr";
import { LoadingCardPqr } from "./components/LoadingCardPqr";

export type LeftSideContentProps = {
    email: string;
};
export const LeftSideContent = ({ email }: LeftSideContentProps) => {
    const { isLoadingPqrs, paginationPqrs, pqrSelected, onGetPqrs } =
        useHelpDeskStore();

    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [debouncedTerm, setDebouncedTerm] = useState<string | null>(
        searchTerm,
    );
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 400);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedTerm === null) {
            return;
        }
        if (debouncedTerm === "") {
            onGetPqrs({
                partner_email: email,
            });
            return;
        }
        onGetPqrs({
            partner_email: email,
            name: debouncedTerm,
        });
    }, [debouncedTerm]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [containerRef.current, isLoadingPqrs]);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                containerRef.current;

            if (
                scrollTop + clientHeight >= scrollHeight - 50 &&
                !isLoadingPqrs
            ) {
                loadPqrs();
            }
        }
    };

    const loadPqrs = async () => {
        if (paginationPqrs?.next_page) {
            onGetPqrs({
                partner_email: email,
                page: paginationPqrs.next_page,
                name: debouncedTerm,
                reset: false,
            });
        }
    };

    const _handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const _getItems = () => {
        return (
            <>
                {paginationPqrs?.items.map((pqr, index) => (
                    <CardPqr
                        key={`card-pqr-${index}`}
                        pqr={pqr}
                        isSelected={pqrSelected?.id === pqr.id}
                    />
                ))}
                {isLoadingPqrs && <LoadingCardPqr key="loading-card-pqr" />}
            </>
        );
    };

    return (
        <Box className="side-content" ref={containerRef}>
            <Box padding="16px">
                <TextField
                    variant="filled"
                    fullWidth
                    placeholder="Buscar PQRs"
                    slotProps={{ input: { disableUnderline: true } }}
                    onChange={_handleOnChangeSearch}
                />
            </Box>
            {_getItems()}
        </Box>
    );
};
